import styled from "styled-components";

export const StyledBurger = styled.button`
	width: 1.5rem;
	height: 1.5rem;
	position: relative;
	cursor: pointer;
	padding: 0;
	z-index: 9999;
	background-color: transparent;
	border: 0;
	outline: none;

	@media (min-width: 66.39rem) {
		display: none;
	}
	span {
		width: 1.5rem;
		height: 0.25rem;
		position: absolute;
		right: 0;
		background-color: ${({ open }) =>
			open ? "var(--white)" : "var(--blue)"};
		transition: all 0.2s linear;
		:first-child {
			top: ${({ open }) => (open ? ".62rem" : ".38rem")};
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
		:nth-child(2) {
			bottom: ${({ open }) => (open ? ".62rem" : ".25rem")};
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}
	}
`;
