import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import "assets/stylesheets/application.scss";

import Header from "components/Header";
import Footer from "components/Footer";

const Layout = ({ children, pageName }) => {
	return (
		<>
			<Helmet bodyAttributes={{ class: `page-${pageName}` }}>
				<html lang="en" />
			</Helmet>
			<div className="wrapper">
				<Header />
				<main>{children}</main>
				<Footer />
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

if (typeof window !== "undefined") {
	// eslint-disable-next-line global-require
	require("smooth-scroll")('a[href*="#"]');
}

export default Layout;
