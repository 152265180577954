import React from "react";
import { Link } from "gatsby";

import Container from "components/Container";

const Footer = () => {
	return (
		<footer>
			{/* <Container sectionName="footer-top">
				<SwissLogo alt="Swissness Logo" />
			</Container> */}
			<Container sectionName="footer-bottom">
				<div className="footer-contact">
					<p>
						Blue Medical AG <br />
						Burgdorf, Switzerland
					</p>
					<p>
						Phone +41 34 421 81 11 <br />
						welcome@bluemedical.swiss
					</p>
				</div>
				<nav className="footer-nav">
					<ul>
						<li>
							<Link to="/company/#career">Career</Link>
						</li>
						<li>
							<Link to="/imprint/">Imprint</Link>
						</li>
					</ul>
				</nav>
			</Container>
		</footer>
	);
};

export default Footer;
