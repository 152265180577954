import React from "react";
import PropTypes from "prop-types";

import Container from "components/Container";

const Intro = ({ children, className }) => {
	if (className) {
		className = `intro ${className}`;
		return <Container sectionName={className}>{children}</Container>;
	} else {
		return <Container sectionName="intro">{children}</Container>;
	}
};

Intro.propTypes = {
	children: PropTypes.node
};

export default Intro;
