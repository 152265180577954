import styled from "styled-components";

export const StyledMenu = styled.nav`
	display: none;

	@media (min-width: 66.39rem) {
		display: block;
		margin-top: 0.75rem;
	}

	a {
		display: block;
		position: relative;
		font-size: 1.25rem;
		line-height: 1.88rem;
		letter-spacing: 0.04rem;
		color: var(--blue);
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: var(--blue);
		}
		&:focus {
			outline: none;
		}
	}

	[aria-current] {
		&:before {
			content: "";
			position: absolute;
			top: -0.31rem;
			height: 3px;
			width: 100%;
			background-color: var(--blue);
		}
	}
`;
