import React from "react";
import PropTypes from "prop-types";

const Container = ({ children, sectionName }) => {
	return <section className={sectionName}>{children}</section>;
};

Container.propTypes = {
	children: PropTypes.node
};

export default Container;
