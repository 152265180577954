import React, { useState } from "react";
// import { bool, func } from "prop-types";
import { Burger } from "./Burger";
import { Menu, MobileMenu } from "./Menu";

const Navbar = () => {
	const [open, setOpen] = useState(false);

	return (
		<div className="navbar-nav">
			<Menu />
			<MobileMenu open={open} />
			<Burger open={open} onClick={() => setOpen(!open)} />
		</div>
	);
};
// Navbar.propTypes = {
// 	open: bool.isRequired,
// 	setOpen: func.isRequired
// };

export default Navbar;
