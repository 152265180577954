import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export const SEO = ({ title, description, keywords, image, url }) => {
	return (
		<StaticQuery
			query={detailsQuery}
			render={(data) => {
				const metaTitle = title || data.site.siteMetadata.title;
				const metaDescription =
					description || data.site.siteMetadata.description;
				const metaUrl = url || data.site.siteMetadata.url;
				const metaKeywords = keywords || [
					"Blue Medical AG",
					"Switzerland",
					"Packaging",
					"Healthcare",
					"Services",
				];
				const previewImagePath = data.allFile.edges[0].node.publicURL;
				const previewImage = metaUrl + previewImagePath;
				return (
					<Helmet
						title={title}
						meta={[
							{
								name: `description`,
								content: metaDescription,
							},
							{
								property: "og:title",
								content: metaTitle,
							},
							{
								property: `og:description`,
								content: metaDescription,
							},
							{
								property: `og:type`,
								content: `website`,
							},
							{
								property: `og:image`,
								content: previewImage,
							},
							{
								property: `og:url`,
								content: metaUrl,
							},
							{
								name: `twitter:title`,
								content: metaTitle,
							},
							{
								name: `twitter:description`,
								content: metaDescription,
							},
							{
								name: `twitter:image`,
								content: previewImage,
							},
						].concat(
							metaKeywords && metaKeywords.length > 0
								? {
										name: `keywords`,
										content: metaKeywords.join(`, `),
								  }
								: []
						)}
					/>
				);
			}}
		/>
	);
};

const detailsQuery = graphql`
	query DefaultSEOQuery {
		site {
			siteMetadata {
				title
				description
				url
			}
		}
		allFile(filter: { name: { eq: "preview" } }) {
			edges {
				node {
					publicURL
				}
			}
		}
	}
`;
