import React from "react";
import { Link } from "gatsby";

import Container from "components/Container";
import Navbar from "components/Navbar";

import Logo from "assets/images/BM_Logo_1_Primary_RGB.svg";

const Header = () => {
	return (
		<header>
			<Container sectionName="navbar">
				<Link to="/" className="header_logo">
					{/* <img
						className="header_logo"
						src={img_logo}
						alt="Blue Medical Logo"
					/> */}
					<Logo alt="Blue Medical Logo" />
				</Link>
				<Navbar />
			</Container>
		</header>
	);
};

export default Header;
