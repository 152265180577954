import React from "react";
import { Link } from "gatsby";
// import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";

const Menu = ({ open, setOpen }) => {
	const isHidden = open ? true : false;
	const tabIndex = isHidden ? 0 : -1;

	const menuItems = [
		{
			id: 1,
			path: "/",
			text: "home"
		},
		{
			id: 2,
			path: "/products/",
			text: "products"
		},
		{
			id: 3,
			path: "/quality/",
			text: "quality"
		},
		{
			id: 4,
			path: "/company/",
			text: "company"
		},
		{
			id: 5,
			path: "/news/",
			text: "news"
		},
		{
			id: 6,
			path: "/contact/",
			text: "contact"
		}
	];

	return (
		<StyledMenu>
			<ul>
				{menuItems.map(link => {
					return (
						<li key={link.id} className="nav-item">
							<Link to={link.path} tabIndex={tabIndex}>
								{link.text}
							</Link>
						</li>
					);
				})}
			</ul>
		</StyledMenu>
	);
};

// Menu.propTypes = {
// 	open: bool.isRequired
// };

export default Menu;
