import styled from "styled-components";

export const StyledMobileMenu = styled.nav`
	visibility: ${({ open }) => (open ? "visible" : "hidden")};
	transition: background-color 0.2s linear;
	display: flex;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9998;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: ${({ open }) => (open ? "var(--blue)" : "var(--white)")};

	@media (min-width: 66.39rem) {
		display: none;
	}

	a {
		position: relative;
		color: var(--white);
		font-size: 1.88rem;
		line-height: 3.75rem;
		opacity: ${({ open }) => (open ? "1" : "0")};
		transition: opacity 0.2s ease-out 0.2s;

		&:hover {
			color: var(--white);
		}

		&:focus {
			outline: none;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
	}

	[aria-current] {
		pointer-events: none;
		opacity: 0.5;
		cursor: default;

		&::before {
			content: none;
		}
	}
`;
